import React from "react";

import { MainLayout } from "../../layout";

export default function Apply() {
  return (
    <MainLayout>
      <h1>Apply</h1>
    </MainLayout>
  );
}
